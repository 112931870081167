<template>
    <div class="CEESAR-SideBar">
        <div class="CEESAR-SideBarButtons">
            <slot name="Buttons"/>
        </div>
        <div class="CEESAR-SideBarContent">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
        name: "CEESAR-SideBar"
}
</script>

<style>
</style>